declare global {
  interface Window {
    plugins?: any;
  }
}

export interface CordovaSamsungPayPlugin {
  /**
   * This method sends a boolean in the success callback to
   * indicate whether or not a device can be setup with
   * Samsung Pay
   */
  isAvailable: (
    success: (isAvailable: boolean) => void,
    error: (e: string) => void
  ) => void;
}

export interface SamsungPayPlugin {
  /**
   * Get available called to check whether
   * Samsung Pay is available based on device
   * and Returns `true` if available else `false`.
   */
  getSamsungPayAvailable(): Promise<boolean>;
}

export class SamsungPayCordovaPlugin implements SamsungPayPlugin {
  constructor(private readonly samsungPayPlugin: CordovaSamsungPayPlugin) {}

  getSamsungPayAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      this.samsungPayPlugin.isAvailable(
        (type: boolean) => resolve(type),
        () => resolve(false)
      );
    });
  }
}

export class SamsungPayCordovaNoopPlugin implements SamsungPayPlugin {
  getSamsungPayAvailable(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
